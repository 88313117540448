window.onload = function() {
	const mapBlock = document.querySelector('.contact-map');
    
    if (mapBlock) {
        const siteWrapper = document.querySelector('.site-wrapper');
        const countries = Array.from(mapBlock.querySelectorAll('.js-country'));
        const officeBlocks = mapBlock.querySelectorAll('.js-contact-office');

        const getPinTemplate = (mod) => {
            const country = mod.dataset.country;
            if (mod.classList.contains('js-contact-map__transform')) {
                return `<img class="contact-map__pin contact-map__transform" width="32" data-country='${country}' height="43" class="" src="/local/templates/teamidea2022/markup/build/assets/img/pin.svg">`;
                //return `<img class="contact-map__pin contact-map__transform" width="32" data-country='${country}' height="43" class="" src="/assets/img/pin.svg">`;
            }
                return `<img class="contact-map__pin" width="32" data-country='${country}' height="43" class="" src="/local/templates/teamidea2022/markup/build/assets/img/pin.svg">`;
                //return `<img class="contact-map__pin" width="32" data-country='${country}' height="43" class="" src="/assets/img/pin.svg">`;
        }

        // отрисовывает пины в середине страны
        countries.forEach(country => {
            siteWrapper.insertAdjacentHTML("afterBegin", getPinTemplate(country));
            const mapPin = siteWrapper.querySelector('.contact-map__pin');

            if (country && mapPin) {
                mapPin.style.top = `${country.getBoundingClientRect().top + window.pageYOffset +
                ((country.getBoundingClientRect().bottom - country.getBoundingClientRect().top) / 2) -
                (mapPin.offsetHeight)}px`;
    
                mapPin.style.left = `${country.getBoundingClientRect().left +
                ((country.getBoundingClientRect().right - country.getBoundingClientRect().left) / 2) -
                (mapPin.offsetWidth / 2)}px`;
            }
        });

        const mapPins = Array.from(siteWrapper.querySelectorAll('.contact-map__pin'));
        // отрисовывает пины в середине страны при ресайзе
        window.addEventListener('resize', () => {
            countries.forEach((country) => {
                const countryName = country.dataset.country;
                const countryPin = mapPins.find(item => item.dataset.country === countryName);

                if (countryPin && country) {
                    countryPin.style.top = `${country.getBoundingClientRect().top + window.pageYOffset +
                    ((country.getBoundingClientRect().bottom - country.getBoundingClientRect().top) / 2) -
                    (countryPin.offsetHeight)}px`;
    
                    countryPin.style.left = `${country.getBoundingClientRect().left +
                    ((country.getBoundingClientRect().right - country.getBoundingClientRect().left) / 2) -
                    (countryPin.offsetWidth / 2)}px`;
                }
            })
        })

        // подсвечивает выбранную страну
        officeBlocks.forEach((block) => {
            block.addEventListener('click', () => {
                const officeName = block.getAttribute('data-country');

                countries.forEach((country, i) => {
                    mapPins[i].classList.remove('contact-map__pin--active');
                    country.classList.remove('contact-map__country--active');
                    officeBlocks[i].classList.remove('contact-map__offices-item--active');
                });

                const country = countries.find((country) => country.getAttribute('data-country') === officeName);
                const pin = mapPins.find((pin) => pin.getAttribute('data-country') === officeName);
                pin.classList.add('contact-map__pin--active');
                country.classList.add('contact-map__country--active');
                block.classList.add('contact-map__offices-item--active');
            })
        })

    }
};
