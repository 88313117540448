const lang = $('html').attr('lang');
const getQuizeData = (initQuize) => {
	const path = location.pathname.split('/');
	fetch('/local/ajax.php?action=quiz&lang='+ lang)
	//fetch('assets/js/mock/quiz.json')
	//fetch('https://teamidea.vasenev.w.ibrush.ru/local/ajax.php?action=quiz&lang=ru')
		.then((res) => res.json())
		.then((data) => {
				initQuize(data)
		})
	.catch(e => {
		console.error(e)
	})
}
