const isIos = () => {
	return [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform)
		// iPad on iOS 13 detection
		||
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

const iosVhFix = () => {
	if (!(!!window.MSInputMethodContext && !!document.documentMode)) {
		if (isIos()) {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);

			window.addEventListener('resize', function() {
				vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			});
		}
	}
};

const showMore = () => {
    $('.js-show-more').on('click', function (e) {
        var showAllClass = 'show-more-show-all';
        const blockEl = $(this).closest('.container').find('.show-more-block');
        e.preventDefault();
        let dataBlockType = $(this).data('blockType');
        if(dataBlockType !== undefined){
            if (dataBlockType === 'flex') {
                showAllClass = 'show-more-show-all-flex';
            }
        }
        let elements = blockEl.children();
        elements.each(function ( index, element ) {
            $(element).addClass(showAllClass);
        })
        $(this).remove();
    })
}

const showMoreAjax = () => {
    $(document).on('click', '.load_more', function (e) {
        var btnContainer = $(this).parent();
        var section = btnContainer.data('section');
        var page = $(this).data('page');
        var targetContainer = btnContainer.find('.js-showMore-container'),
            url = $(this).attr('data-url');
        if (btnContainer.hasClass('js-cases__show-more')) {
            targetContainer = btnContainer.parent();
        }

        if (url !== undefined) {
            url += `&section=${section}`;
            if(!!page){
                let location = new URL(window.location.href);
                location.searchParams.set('page', page)
                window.history.replaceState({}, "", location)
            }
            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'html',
                success: function (data) {
                    btnContainer.find('.load_more').remove();
                    var elements = $(data).find('.js-showMore-item'),
                        pagination = $(data).find('.load_more');
                    targetContainer.append(elements);
                    btnContainer.append(pagination);
                    if (btnContainer.hasClass('js-cases__show-more')) {
                        targetContainer.find('.js-cases__show-more').remove();
                        pagination = $(data).find('.js-cases__show-more');
                        targetContainer.append(pagination);
                    }
                    $(window).lazyLoadXT();
                }
            })
        }
    });
}

const showMoreSearchAjax = () => {
    $(document).on('click', '.js-search__show-more', function (e) {
        e.preventDefault();
        const url = $(this).attr('data-url');
        const selectedElementValue = "&section=" + $('.js-section-selector-search').val();
        $('#search_result').html('');
        $.ajax({
            type: "POST",
            url: "/search/ajax_search.php",
            data: "q=" + q + "&" + url + selectedElementValue,
            dataType: 'json',
            success: function (json) {
                $('.js-search__text').html(json.COUNT);
                if (json.NAV_STRING !== undefined)
                    $('.js-search-paginator').html(json.NAV_STRING);
                $.each(json.ITEMS, function (index, element) {
                    $('.js-search__results').append(
                        '<li class="search__results-item js-search__results-item js-showMore-item">' +
                        '<a target="_blank" class="search__results-link" href="' + element.URL + '">' +
                        '<p class="search__results-title">' + element.TITLE + '</p>' +
                        '<p class="search__results-text">' + element.BODY_FORMATED +
                        '</p></a></li>'
                    );
                });
            }
        });
    });
}

const swipeToQuizeAnchor = (topCustom = 75) => {
    $("html, body").animate({
        scrollTop: $($('#answer_block')).offset().top - topCustom + "px"
    }, {
        duration: 300,
        easing: "swing"
    });
}

const swipeToAnchor = (topCustom = 75) => {
    $('.js-swipe-to-anchor').click(function (e) {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top - topCustom + "px"
        }, {
            duration: 300,
            easing: "swing"
        });
    });
}

const ultrawideMonitorCheck = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    if (screenWidth / screenHeight > 2) {
        const siteWrapper = document.querySelector('.site-wrapper');
        const htmlTag = document.querySelector('html');
        siteWrapper.classList.remove('scale');
        htmlTag.style.fontSize = '62.5%';
    }
}

const addScript = (src, size = 0) => {
    if(document.body.clientWidth > size) {
        var script = document.createElement('script');
        script.src = src;
        script.async = false;
        document.head.appendChild(script);
    }

}

const getLangYaMapsUrl = () => {
    let htmlLangAttr = document.documentElement.lang;
    switch (htmlLangAttr) {
        case 'en': {
            return 'https://api-maps.yandex.ru/2.1/?apikey=457ee028-2a3e-4902-b3d0-120a70661f97&lang=en_US';
        }
        default: {
            return 'https://api-maps.yandex.ru/2.1/?apikey=457ee028-2a3e-4902-b3d0-120a70661f97&lang=ru_RU';
        }
    }
}
addScript(getLangYaMapsUrl(), 400);

iosVhFix();
showMore();
showMoreAjax();
swipeToAnchor();
ultrawideMonitorCheck();
showMoreSearchAjax();
