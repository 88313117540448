const quizBlock = document.querySelector('.js-quiz');

if (quizBlock) {
	let MAX_QUESTIONS = null;
	let clickedButtons = {};
	let isQuizFinished = false;
	let quizStep = 1;
	let clickCounter = 0;
	const questionBlock = quizBlock.querySelector('.js-quiz-qestion');
	const buttonsContainer = quizBlock.querySelector('.quiz__buttons');
	const countBlock = quizBlock.querySelector('.js-quiz-count');
	const questionNumberBlock = quizBlock.querySelector('.js-quiz-length');
	const offerBlock = document.querySelector('.quiz-list');
	const showMoreButton = document.querySelector('.js-quiz-load-more');

	const getTemplate = {
		button({ name, id }) {
			return `<button class="button button--white button--round quiz__button js-swipe-to-anchor" href="#answer_block" type="button" data-id="${id}">${name}</button>`;
		},
		offerCard({ title, link, text, img }, i, {MORE}) {
			return `<li class="quiz-list__item ${i > 3 ? `mobile-hide` : `` }">
                <a class="quiz-list__link" href="${link}">
                    <div class="quiz-list__container">
                        <span class="quiz-list__title">${title}</span>
                        <p class="quiz-list__text">${text}</p>
                    </div>
                    <div class="quiz-list__wrapper">
                        <img width="46" height="46" src="${img}" alt="" class="quiz-list__img">
                        <span class="link-detail">${MORE} +</span>
                    </div>
                </a>
            </li>`;
		},
		bigOfferCard({ title, link, text, img, tags }, i, {MORE}) {
			let tagsList = [];
			const tagsRender = (tags) => {
				tags.forEach(item => {
					tagsList.push(`<li class="tag__item">
                    <a href="${item.link}" class="tag__link">${item.title}</a>
                </li>`)
				});

				return tagsList.join(``).toString();
			}
			return `<li class="quiz-list__item quiz-list__item--last ${i > 3 ? `mobile-hide` : `` }">
                <div class="quiz-list__last-wrap">
                    <span class="quiz-list__title">${title}</span>
                    <p class="quiz-list__text">${text}</p>
                </div>
                <div class="quiz-list__last-wrap quiz-list__last-wrap--last">
                    <ul class="tag">
						${tagsRender(tags)}
                    </ul>

                    <div class="quiz-list__wrapper">
                        <img width="46" height="46" src="${img}" alt="" class="quiz-list__img">
                        <a  class="link-detail" href="${link}">${MORE} +</a>
                    </div>
                </div>
            </li>`;
		}
	}

	const renderQuestionBlock = ({ questions_text, first_question_list, questions }) => {
		if (quizStep <= MAX_QUESTIONS) {
			questionBlock.textContent = questions_text[quizStep - 1];
		}

		if (quizStep === 1) {
			first_question_list.forEach(question => {
				buttonsContainer.insertAdjacentHTML('afterbegin', getTemplate.button(question));
			})
		} else {
			buttonsContainer.innerHTML = "";
			questions.forEach(question => {
				buttonsContainer.insertAdjacentHTML('afterbegin', getTemplate.button(question));
			})
		}
	}

	const ckeckQuezeFinish = () => {
		if (quizStep > MAX_QUESTIONS) {
			isQuizFinished = true;
		}
	}

	const getClickedButtons = (evt) => {
		if (evt.target.classList.contains('quiz__button')) {
			clickedButtons[`questionNumber${quizStep}`] = {
				"id": +evt.target.dataset.id,
			}

            if (quizStep === MAX_QUESTIONS) {
                swipeToQuizeAnchor(50);
            }

			clickCounter++;
		}
	}

	const updateButtonBlock = (evt) => {
		if (evt.target.classList.contains('quiz__button') && quizStep !== 0) {
			if (quizStep < MAX_QUESTIONS) {
				quizStep++;
			}

			if (quizStep <= MAX_QUESTIONS) {
				countBlock.textContent = quizStep;
			}
		}
	}

	const getRenderData = (questions, options) => {
		let renderData = [];
		const offers = questions.find(question => question.id === clickedButtons.questionNumber2.id)
		.answers.find(answer => answer.id === clickedButtons.questionNumber1.id).options;

		options.forEach(option => {
			offers.forEach(offer => {
				if (+option.id === offer) {
					renderData.push(option);
				}
			})
		})

		return renderData;
	}

	const setModalListners = () => {
		if (clickCounter >= MAX_QUESTIONS) {
			modalInit(true);
		}
	}

	const setActiveButton = (evt) => {
		if (evt.target.classList.contains('quiz__button') && clickCounter >= MAX_QUESTIONS) {
			const buttonId = evt.target.dataset.id;
			const buttons = quizBlock.querySelectorAll('.quiz__button');
			buttons.forEach(button => {
				if (button.dataset.id === buttonId) {
					button.classList.add('quiz__button--active');
				}
			})
		}
	}

	const renderOffers = ({questions, LANG_PHRASE, options}) => {
		if (clickCounter >= MAX_QUESTIONS) {
			let renderData = getRenderData(questions, options);

			if (document.body.clientWidth < 768 && renderData.length > 4) {
				console.log(renderData.length)
				showMoreButton.style.display = "block";
			} else if (renderData.length <= 4)  {
				showMoreButton.style.display = "none";
			}

			offerBlock.innerHTML = "";
			renderData.forEach((item, i) => {
				if (item.big_card) {
					offerBlock.insertAdjacentHTML('beforeend', getTemplate.bigOfferCard(item, i,LANG_PHRASE));
				} else {
					offerBlock.insertAdjacentHTML('afterbegin', getTemplate.offerCard(item, i, LANG_PHRASE));
				}
			})
		}
	}


	const startQuiz = (data) => {
		quizBlock.addEventListener('click', (evt) => {

			//ckeckQuezeFinish();
			if (!isQuizFinished && evt.target.classList.contains('quiz__button')) {
				getClickedButtons(evt);
				updateButtonBlock(evt);
				renderQuestionBlock(data, evt);
				renderOffers(data);
				setModalListners();
				setActiveButton(evt);
			}
		});
	}

	const initQuize = (data) => {
		console.log(data)
        MAX_QUESTIONS = data.questions_text.length;
        questionNumberBlock.textContent = MAX_QUESTIONS;
		renderQuestionBlock(data);
		startQuiz(data);
        showAllMobile();
	}

	getQuizeData(initQuize);
}
