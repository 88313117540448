document.addEventListener('DOMContentLoaded', () => {
	// buttons
	const prevButtongray = `<button class="slider__button slider__button--gray slider__button--prev" type="button">
					<svg class="slider__button-svg" width="32" height="16">
							<use xlink:href="#slider-arrow"></use>
					</svg>
			</button>`;

	const nextButtongray = `<button class="slider__button slider__button--gray slider__button--next" type="button">
					<svg class="slider__button-svg" width="32" height="16">
							<use xlink:href="#slider-arrow"></use>
					</svg>
			</button>`;

	function showButtons(wrapper, toggleShowElement) {
		const slider = wrapper.closest('.swiper');
		if (toggleShowElement) {
			if (wrapper.scrollWidth <= slider.clientWidth) {
				toggleShowElement?.classList.add('_hide');
			} else {
				toggleShowElement?.classList.remove('_hide');
			}
		}
	}


	if (document.querySelector('.js-quiz-slider')) {
		const sliders = document.querySelectorAll('.js-quiz-slider');

		sliders.forEach(slider => {
			const tabsBtns = slider.previousElementSibling;
			const activeBtns = tabsBtns.getElementsByClassName('is-active');

			const swiper = new Swiper(slider, {
				allowTouchMove: false,
				autoHeight: true,
				effect: 'fade',
				fadeEffect: {
					crossFade: true,
				},
			});

			tabsBtns.addEventListener('click', ({target}) => {
				const btn = target.closest('.js-quiz-slider-btn');
				const [active] = activeBtns;

				if (btn) {
					btn.classList.add('is-active');
					active.classList.remove('is-active');
					swiper.slideTo(btn.dataset.slide, 400);
				}
			});
		});
	}

	// -------------- main-slider-top ---------------
	if (document.querySelector('.js-top-main-slider')) {
		const sliders = document.querySelectorAll('.js-top-main-slider');

		sliders.forEach(slider => {
			const progressWrap = slider.querySelector('.js-main-slider-progress');

			new Swiper(slider, {
				loop: false,
				rewind: true,
				slidesPerView: 'auto',
				autoplay: {
					delay: 5000,
					disableOnInteraction: false
				},
				on: {
					init: (swiper) => {
						renderProgressItems(swiper);
					},
					snapIndexChange(swiper) {
						if (swiper.snapIndex && swiper?.progressItems.length) {
							swiper.progressItems[swiper.snapIndex - 1]?.classList.add('is-done');
							swiper.progressItems[swiper.snapIndex - 1]?.style.setProperty("--progress", 0);
						} else {
							swiper.progressItems?.forEach(item => {
								item?.classList.remove('is-done');
								item?.style.setProperty("--progress", 0);
							});
						}

						if (swiper.progressItems && swiper.progressItems.length) {
							progress(swiper);
						}
					},
					resize(swiper) {				
						if (swiper?.progressItems && swiper.snapGrid.length === swiper.progressItems.length) return;

						renderProgressItems(swiper);
					}
      	}
			});

			function renderProgressItems(swiper) {
				progressWrap.innerHTML = '';
	
				if (swiper.snapGrid.length > 1) {
					swiper.snapGrid.forEach((item, index) => {
						progressWrap.innerHTML += `<div class="main__slider-progress-item${index < swiper.snapIndex ? ' is-done' : ''}"></div>`
						swiper.progressItems = [...progressWrap.querySelectorAll('.main__slider-progress-item')];
						swiper.autoplay.start();
						progress(swiper);
					});
				} else {
					swiper.progressItems = [];
					swiper.autoplay.stop();
				}	
			}
			
			function progress(swiper) {
				const start = performance.now();

				requestAnimationFrame(function animate(time) {
					const progress = (time - start) / swiper.params.autoplay.delay;

					if (swiper.progressItems) {
						swiper.progressItems[swiper.snapIndex]?.style.setProperty("--progress", progress);
	
						if (progress < 1) requestAnimationFrame(animate);
					}
				});
			}
		});
	}

	// -------------- main-slider ---------------
	if (document.querySelector('.js-main-slider')) {
		const sliders = document.querySelectorAll('.js-main-slider');

		sliders.forEach(slider => {
			const container = slider.closest('.container'),
			buttons = container.querySelector('.slider__buttons'),
			prev = container.querySelector('.slider__button--prev'),
			next = container.querySelector('.slider__button--next'),
			wrapper = slider.querySelector('.swiper-wrapper');

			const sliderConfig = new Swiper(slider, {
				slidesPerView: 1,
				loop: false,
				navigation: {
					nextEl: next,
					prevEl: prev,
				},
				breakpoints: {
					560: {
						slidesPerView: 'auto',
					},
					1850: {
						slidesPerView: 4,
					}
				}
			})

			showButtons(wrapper, buttons);
			sliderConfig.on('resize', () => showButtons(wrapper, buttons));
		})
	}

	// -------------- team-slider ---------------
	if (document.querySelector('.js-team-slider')) {
		const slider = document.querySelector('.js-team-slider');

		const container = slider.closest('.container'),
		buttons = container.querySelector('.slider__buttons'),
		prev = container.querySelector('.slider__button--prev'),
		next = container.querySelector('.slider__button--next'),
		wrapper = slider.querySelector('.swiper-wrapper');

		const sliderConfig = new Swiper(slider, {
			loop: false,
			slidesPerView: 'auto',
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
			grid: {
				rows: 2,
			},
		})

		showButtons(wrapper, buttons);
		sliderConfig.on('resize', () => showButtons(wrapper, buttons));
	}

    if (document.querySelector('.js-contact-map-offices')) {
        const slider = document.querySelector('.js-contact-map-offices'),
            wrapper = slider.querySelector('.swiper-wrapper'),
            buttons = slider.closest('.container').querySelector('.slider__buttons');

        if (window.innerWidth >= 768) {
            const sliderConfig = new Swiper(slider, {
                slidesPerView: 'auto',
                spaceBetween: 20,
                navigation: {
                    nextEl: '.slider__button--next',
                    prevEl: '.slider__button--prev',
                },
                breakpoints: {
                    1367: {
                        spaceBetween: 24,
                    },
                    1900: {
                        spaceBetween: 24,
                        slidesPerView: 4,
                    },
                },
            })

            showButtons(wrapper, buttons);
            sliderConfig.on('resize', () => showButtons(wrapper, buttons));
        }
    }
	if( window.innerWidth < 1199 ){
		new Swiper('.experience__slider', {
			spaceBetween: 16,
			slidesPerView: 'auto',
			navigation: {
			nextEl: '.experience__button-next',
			prevEl: '.experience__button-prev',
			},
		    breakpoints: {
				320: {
					spaceBetween: 16
				},
				1199: {
					spaceBetween: 0
				}
			  }
		  });
	}
	if (document.querySelector('.recommend-letters')) {
		new Swiper('.recommend-letters', {
			spaceBetween: 24,
			slidesPerView: 'auto',
			navigation: {
			nextEl: '.about-company__next',
			prevEl: '.about-company__prev',
			},
		 
		  });
	}
	// ----------------- case-slider -------------------
	$('.js-case-slider').slick({
			lazyLoad: 'progressive',
		infinite: false,
		slidesToShow: 3,
		variableWidth: true,
		appendArrows: $('.js-case-slider-buttons'),
		prevArrow: prevButtongray,
		nextArrow: nextButtongray,
		responsive: [
					{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
									variableWidth: false,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
									variableWidth: false,
				}
			},
							{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
									variableWidth: true,
				}
			}
		],
	});

	// -------------- solution-device-slider --------
	if (document.querySelector('.js-solution-device-slider')) {
		const slider = document.querySelector('.js-solution-device-slider .swiper');
		const nextEl = document.querySelector('.js-solution-device-slider .slider__button--next');
		const prevEl = document.querySelector('.js-solution-device-slider .slider__button--prev');

		console.log(prevEl);
		new Swiper(slider, {
			navigation: {
				nextEl,
				prevEl,
			}
		});
	}

	// ----------------- awards-slider -------------------
	const slidesCount = document.querySelectorAll('.js-awards-slider .slider__slide');

	$('.js-awards-slider').slick({
			lazyLoad: 'progressive',
		infinite: false,
		slidesToShow: 6,
		appendArrows: $('.js-awards-slider-buttons'),
		prevArrow: prevButtongray,
		nextArrow: nextButtongray,
		initialSlide: slidesCount.length > 6 ? slidesCount.length - 6 : false,
		responsive: [
					{
				breakpoint: 1600,
				settings: {
					slidesToShow: 5,
					initialSlide: slidesCount.length > 5 ? slidesCount.length - 5 : false,
				}
			},
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 4,
					initialSlide: slidesCount.length > 4 ? slidesCount.length - 4 : false,
				}
			},
					{
				breakpoint: 900,
				settings: {
					slidesToShow: 3,
					initialSlide: slidesCount.length > 3 ? slidesCount.length - 3 : false,
				}
			},
					{
				breakpoint: 768,
				settings: {
									variableWidth: true,
					slidesToShow: 1,
					initialSlide: slidesCount.length
				}
			},
		],
	});

	// ----------------- trends-slider-main-page -------------------
	$('.js-trends-slider-main').each(function() {
		if (window.innerWidth > 1366) {
			const slides = this.querySelectorAll('.slider__slide');

			for (let i = 0; i < slides.length; i++) {
				if (i % 3 === 0) {
					const nextOne = slides[i + 1],
					nextTwo = slides[i + 2];

					if (nextOne) {
						nextOne?.classList.add('trends__item-grid')
						slides[i].append(nextOne);
					}

					if (nextTwo) {
						nextTwo?.classList.add('trends__item-grid')
						slides[i].append(nextTwo);
					}
				}
			}
		}

		$(this).slick({
			lazyLoad: 'ondemand',
			infinite: false,
			slidesToShow: 1,
			appendArrows: $('.js-trends-slider-buttons'),
			prevArrow: prevButtongray,
			nextArrow: nextButtongray,
			adaptiveHeight: true,
		});
	})

	//-------------------- clients-slider --------------------------
	$('.js-clients-slider').slick({
		lazyLoad: 'progressive',
		infinite: false,
		slidesToShow: 6,
		slidesToScroll: 2,
		appendArrows: $('.js-clients-slider-buttons'),
		prevArrow: prevButtongray,
		nextArrow: nextButtongray,
		responsive: [
					{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
				}
			},
					{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
				}
			},
		],
	});

	//------------------ similar-slider business-case-details-page------------------
	$('.js-similar-slider').slick({
			lazyLoad: 'progressive',
		infinite: false,
		slidesToShow: 3,
			slidesToScroll: 1,
		appendArrows: $('.js-similar-slider-buttons'),
		prevArrow: prevButtongray,
		nextArrow: nextButtongray,
		responsive: [
					{
				breakpoint: 1366,
				settings: {
					slidesToShow: 2,
				}
			},
					{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				}
			},
		],
	});

	//------------------ similar-slider solution-details-page------------------
	$('.js-similar-slider-solutions').slick({
			lazyLoad: 'progressive',
		infinite: false,
		slidesToShow: 4,
			slidesToScroll: 1,
		appendArrows: $('.js-similar-slider-solutions-buttons'),
		prevArrow: prevButtongray,
		nextArrow: nextButtongray,
		responsive: [
					{
				breakpoint: 1366,
				settings: {
					slidesToShow: 3,
				}
			},
					{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				}
			},
					{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				}
			},
		],
	});

	//------------------ video-slider solution-details-page------------------
	const videoSlider = $('.js-solutions-video-slider');

	if (videoSlider[0]) {
		const isMobile = () => {
			if (document.body.clientWidth > 768) {
				return '.js-solutions-video-slider-preview';
			}
			return false;
		}

		const slides = document.querySelectorAll('.js-solutions-video-slider .slider__slide');

		slides.forEach((slide, i) => {
			slide.setAttribute('data-id', i + 1);
		});

		const counterCurrent = document.querySelector('.solution-details__video-slider .js-slider-counter-current');
		const counterAll = document.querySelector('.solution-details__video-slider .js-slider-counter-all');
		counterAll.textContent = slides.length > 10 ? `${slides.length}` : `0${slides.length}`;

		const videoSliderCounter = () => {
			const currentSlide = document.querySelector('.js-solutions-video-slider .slick-current');
			const currentSlideId = currentSlide.dataset.id;
			counterCurrent.textContent = currentSlideId > 10 ? `${currentSlideId}` : `0${currentSlideId}`;
		}

		videoSlider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			appendArrows: $('.js-solutions-video-slider-buttons'),
			prevArrow: prevButtongray,
			nextArrow: nextButtongray,
			asNavFor:  isMobile(),
		});

		if (document.body.clientWidth > 768) {
			$('.js-solutions-video-slider-preview').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				asNavFor: '.js-solutions-video-slider',
			});
		}

		videoSlider.on('afterChange', videoSliderCounter);
	}

	//------------------ pictures-slider solution-details-page------------------
	const picturesSlider = $('.js-solutions-pictures-slider');

	if (picturesSlider[0]) {
			const isMobile = () => {
					if (document.body.clientWidth > 768) {
							return '.js-solutions-pictures-slider-preview';
					}
					return false;
			}

			const slides = document.querySelectorAll('.js-solutions-pictures-slider .slider__slide');

			slides.forEach((slide, i) => {
					slide.setAttribute('data-id', i + 1);
			});

			const counterCurrent = document.querySelector('.solution-details__pictures-slider .js-slider-counter-current');
			const counterAll = document.querySelector('.solution-details__pictures-slider .js-slider-counter-all');
			counterAll.textContent = slides.length > 10 ? `${slides.length}` : `0${slides.length}`;

			const picturesSliderCounter = () => {
					const currentSlide = document.querySelector('.js-solutions-pictures-slider .slick-current');
					const currentSlideId = currentSlide.dataset.id;
					counterCurrent.textContent = currentSlideId > 10 ? `${currentSlideId}` : `0${currentSlideId}`;
			}

			picturesSlider.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					appendArrows: $('.js-solutions-pictures-slider-buttons'),
					prevArrow: prevButtongray,
					nextArrow: nextButtongray,
					asNavFor:  isMobile(),
			});

			if (document.body.clientWidth > 768) {
					$('.js-solutions-pictures-slider-preview').slick({
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							asNavFor: '.js-solutions-pictures-slider',
					});
			}

			picturesSlider.on('afterChange', picturesSliderCounter);
	}

	//------------------ read-else slider------------------
	$('.js-read-else-slider').slick({
		slidesToShow: 3,
			slidesToScroll: 1,
		appendArrows: $('.js-read-else-slider-buttons'),
		prevArrow: prevButtongray,
		nextArrow: nextButtongray,
		responsive: [
					{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				}
			},
					{
				breakpoint: 650,
				settings: {
					slidesToShow: 1,
				}
			},
		],
	});

	//------------------ employee-reviews-slider------------------

	// desktop-slider

	if (document.body.clientWidth > 1024) {
		$('.js-employee-rate-slider-desktop').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			appendArrows: $('.js-employee-rate-slider-buttons'),
			prevArrow: prevButtongray,
			nextArrow: nextButtongray,
		});
	}

	// mobile-slider
	if (document.body.clientWidth < 1025)  {
		$('.js-employee-rate-slider-mobile ').slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			appendArrows: $('.js-employee-rate-slider-buttons'),
			prevArrow: prevButtongray,
			nextArrow: nextButtongray,
					responsive: [
							{
								breakpoint: 800,
								appendArrows: false,
								settings: 'unslick'
							}
						]
		});
	}

	//------------------- our-work-slider --------------------
	const workSlider = $('.js-our-work-slider');

	let videoSliderCounter = null;

	if (workSlider[0]) {
		const slides = document.querySelectorAll('.js-our-work-slider .slider__slide');

		if (document.body.clientWidth < 768)  {
			slides.forEach((slide, i) => {
				slide.setAttribute('data-id', i + 1);
			});

			const counterCurrent = document.querySelector('.career-page__our-work-slider .js-slider-counter-current');
			const counterAll = document.querySelector('.career-page__our-work-slider .js-slider-counter-all');
			counterAll.textContent = slides.length > 10 ? `${slides.length}` : `0${slides.length}`;

			videoSliderCounter = () => {
				const currentSlide = document.querySelector('.career-page__our-work-slider .slick-current');
				const currentSlideId = currentSlide.dataset.id;
				counterCurrent.textContent = currentSlideId > 10 ? `${currentSlideId}` : `0${currentSlideId}`;
			}
		}

			firstSlideIsTitle = () => {
					const currentSlide = document.querySelector('.career-page__our-work-slider .slick-current');
					var dots = $('.slick-dots li');
					dots.each( function( k, v){
							$(this).find('button').html(k);
							if (k === 0)
									$(v).find('button').html('-');
					});
			}

			workSlider.on('init', firstSlideIsTitle);

		workSlider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: true,
			responsive: [
				{
					breakpoint:768,
					settings: {
						arrows: true,
						appendArrows: $('.js-our-work-slider-buttons'),
						prevArrow: prevButtongray,
						nextArrow: nextButtongray,
						dots: false,
					}
				},
			],
		});

		if (document.body.clientWidth < 768) {
			workSlider.on('afterChange', videoSliderCounter);
		}
	}

	//------------------ employee-review-slider ------------------
	$('.js-employee-review-slider').slick({
		slidesToShow: 2,
			slidesToScroll: 1,
		appendArrows: $('.js-employee-review-slider-buttons'),
		prevArrow: prevButtongray,
		nextArrow: nextButtongray,
		responsive: [
					{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				}
			},
		],
	});

	//------------------ our-nubmer-slider------------------

	// desktop-slider

	if (document.body.clientWidth > 1024) {
		$('.js-our-numbers-slider-desktop').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			appendArrows: $('.js-our-numbers-slider-buttons'),
			prevArrow: prevButtongray,
			nextArrow: nextButtongray,
		});
	}

	// mobile-slider
	if (document.body.clientWidth < 1025)  {
		$('.js-our-numbers-slider-mobile').slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			appendArrows: $('.js-our-numbers-slider-buttons'),
			prevArrow: prevButtongray,
			nextArrow: nextButtongray,
		});
	}

	if (document.body.clientWidth < 768)  {
		$('.js-trends-mobile-slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			appendArrows: $('.js-trends-mobile-slider-buttons'),
			prevArrow: prevButtongray,
			nextArrow: nextButtongray,
		});
	}

	
	document.querySelectorAll('.js-line-slider').forEach(slider => 
		new Swiper(slider, {
			loop: false,
			slidesPerView: 'auto',
			freeMode: true,
			spaceBetween: 16,
			enabled: true,
			breakpoints: {
				1200: {
					enabled: false,
					spaceBetween: 24,
				}
			},
			on: {
				resize(swiper) {
					if (innerWidth >= 1200 && swiper.activeIndex) {
						swiper.translateTo(0, 0);
					}
				}
			}
		})
	);
	

	document.querySelectorAll('.js-main-solution-details-slider').forEach(slider => {
		const swiper = slider.querySelector('.swiper');
		const nextEl = slider.querySelector('.slider__button--next');
		const prevEl = slider.querySelector('.slider__button--prev' );

		new Swiper(swiper, {
			loop: false,
			slidesPerView: 'auto',
			spaceBetween: 16,
			navigation: {
				nextEl,
				prevEl,
			},
			breakpoints: {
				1200: {
					spaceBetween: 24,
				}
			}
		});
	});
});
