const videoModal = document.querySelector('.js-modal-video');
const playButtons = document.querySelectorAll('.js-previews-video-modal-btn');
if (videoModal && playButtons.length > 0) {

    const videoContainer = videoModal.querySelector('.js-modal-video-container');
    
    const handleCloseButtonClick = (evt) => {
        if (evt.target.closest('.js-modal-close')) {
            stopVideo();
        }
    }
    
    const handleEscPress = (evt) => {
        if (evt.key === 'Escape') {
            stopVideo();
        }
    };

    const handleOverlayClick = (evt) => {
        if (evt.target.classList.contains('modal')) {
            stopVideo();
        }
    };
    
    const stopVideo = () => {
        videoContainer.innerHTML = '';
        videoModal.removeEventListener('click', handleCloseButtonClick);
        window.removeEventListener('keydown', handleEscPress);
        videoModal.removeEventListener('click', handleOverlayClick);
    }
    
    playButtons.forEach(button => {
        button.addEventListener('click', () => {
            const videoLink = button.dataset.video;
            const template = `<iframe width="560" height="315" src="${videoLink}" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
            gyroscope; picture-in-picture" allowfullscreen></iframe>`;
            videoContainer.innerHTML = template;
            videoModal.addEventListener('click', handleCloseButtonClick);
            window.addEventListener('keydown', handleEscPress);
            videoModal.addEventListener('click', handleOverlayClick);
        })
    })
}
