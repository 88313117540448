document.querySelectorAll('.js-copy-link').forEach(btn => {
  let timeoutId = null;

  btn.addEventListener('click', () => {
    copyTextToClipboard(document.URL, () => {
      if (!timeoutId) {
        btn.nextElementSibling.style.opacity = '1';
        timeoutId = setTimeout(() => {
          timeoutId = null;
          btn.nextElementSibling.style.opacity = '0';
        }, 5000);
      }
    });
  }); 
});

function copyTextToClipboard(text, successCallback = null, unsuccessCallback = null) {
  const textArea = document.createElement("textarea");

  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";

  textArea.style.width = "2em";
  textArea.style.height = "2em";

  textArea.style.padding = "0";

  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";

  textArea.style.background = "transparent";

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");

    if (successful) {
      successCallback?.();
    } else {
      unsuccessCallback?.();
    }
  } catch (err) {
    unsuccessCallback?.();
  }

  document.body.removeChild(textArea);
}