const articleNavHead = document.querySelector('.js-article-nav-head');

if (articleNavHead) initArticleNav();

function initArticleNav() {

    let buttons = document.querySelectorAll('.blog-detail-page__info-btn')
    console.log(buttons)
    buttons.forEach(function(elem){
        console.log(elem)
        elem.innerHTML = '<svg width="16" height="16"><use xlink:href="#info"></use></svg>';
    })

    let anchors = document.querySelectorAll('.blog-anchor')

    if(anchors.length <= 0){
        anchors = document.querySelectorAll('.blog-detail-page__text h2')
    }


    let navList = document.querySelector('.article-nav__list')
    let i = 1;
    anchors.forEach(function(elem){
        let li = document.createElement('li')
        li.classList.add('article-nav__item')
        let a = document.createElement('a')

        if(!elem.id){
            elem.id = 'link'+i;
        }

        a.href = '#'+elem.id
        a.classList.add('article-nav__link')
        a.innerText = elem.innerText
        li.appendChild(a)
        navList.appendChild(li)
        i++;
    })

    if(anchors.length <= 0){
        articleNavHead.style.display = 'none'
        return false
    }

  articleNavHead.addEventListener('click', () => {
    let start = 0; end = 0;
    const wrap = articleNavHead.nextElementSibling;
    const list = wrap.firstElementChild;

    articleNavHead.classList.toggle('is-hidden');

    if (articleNavHead.classList.contains('is-hidden')) {
      start = list.clientHeight;
    } else {
      end = list.clientHeight;
    }

    if (list) {
      wrap.animate([{height: `${start}px`}, {height: `${end}px`}], {duration: 300});
    }
  })
}
