
const forms = document.querySelectorAll('.js-validate-form');

const closeModal = () => {
    $('.js-modal-close').trigger('click');
};

const openSuccessModal = () => {
    $('.js-success-modal-button').trigger('click');
};

const beautifulAlert = (title, message) => {
    $('.js-beautiful-alert .modal__success-status h3').html(title);
    $('.js-beautiful-alert .modal__success-status p').html(message);
    $('.js-beautiful-alert-button').trigger('click');
};

const initTelMask = (inputTelNumber) => {
	if (inputTelNumber) {
		inputTelNumber.addEventListener('focus', () => {
			IMask(inputTelNumber, {
				mask: '+0 000 000-00-00',
			});
		})
	}
};

if (forms[0]) {
	const validateText = (textInputs) => {
		let isValid = true;
		if (textInputs[0]) {
			textInputs.forEach(input => {
				if (input.value === '') {
					input.closest('.input').classList.add('input--error');
					isValid = false;
				} else {
					input.closest('.input').classList.remove('input--error');
					isValid = true;
				}
			});
		}
		return isValid;
	};

	const validateTel = (teltInput) => {
		let isValid = true;
		if (teltInput) {
			if (teltInput.value.length !== 16) {
				teltInput.closest('.input').classList.add('input--error');
				isValid = false;
			} else {
				teltInput.closest('.input').classList.remove('input--error');
				isValid = true;
			}
		}

		return isValid;
	};

	const validateEmail = (emailInput) => {
		const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		let isValid = true;
		if (emailInput) {
			if (emailInput.value.match(reg) === null) {
				emailInput.closest('.input').classList.add('input--error');
				isValid = false;
			} else {
				emailInput.closest('.input').classList.remove('input--error');
				isValid = true;
			}
		}

		return isValid;
	};

	forms.forEach(form => {
		const textInputs = form.querySelectorAll('.js-form-text');
		const submitButton = form.querySelector('.js-form-btn');
		const telInput = form.querySelector('.js-form-tel');
		const emailInput = form.querySelector('.js-form-email');
		initTelMask(telInput);

        form.addEventListener('submit', (evt) => {
            var isError = false;
            evt.preventDefault();
			if (!validateText(textInputs)) {
				isError = true;
			}

			if (!validateTel(telInput)) {
                isError = true;
			}

			if (!validateEmail(emailInput)) {
                isError = true;
			}

            if (!isError) {
                beforeSendForm();
                let fetchUrl = form.action || '/';
                const data = new URLSearchParams();
                for (const pair of new FormData(evt.target)) {
                    data.append(pair[0], pair[1]);
                }

                if ($(form).data('isCrm') === true) {
                    let config = {
                        fields: {
                            "Name": '#' + $(form).find('input[name="f[name]"]').attr('id'),
                            "MobilePhone": '#' + $(form).find('input[name="f[tel]"]').attr('id'),
                            "Email": '#' + $(form).find('input[name="f[email]"]').attr('id'),
                            "Company": '#' + $(form).find('input[name="f[url]"]').attr('id'),
                        },
                        landingId: "570dcad0-8b86-4325-a4c1-514e6e73c5a4",
                        serviceUrl: "https://tde-bandldemo-qa134.bpmconnect.ru:443/0/ServiceModel/GeneratedObjectWebFormService.svc/SaveWebFormObjectData",
                        onSuccess: function (response) {
                            //console.log('success', response);
                        },
                        onError: function (response) {
                            console.log('error', response);
                        }
                    };
                    landing.createObjectFromLanding(config);
                }
                fetch(fetchUrl,
                {
                    method: 'POST',
                    body: data
                })
                .then(res => {
                    if(res.ok) {
                        var response = res.json();
                        return response;
                    } else {
                        throw new Error('consultation response error');
                    }
                }).then(data => {
                const response = {
                    ...data,
                }
                if(response[0] !== null) {
                    if( response.status == 'success' ) {
                        closeModal();
                        openSuccessModal();
                        $(form).trigger('reset');
                        setTimeout(function () {
                            closeModal();
                        }, 2000);
                    }else if(response.title && response.message){
                        beautifulAlert(response.title, response.message);
                    }else{
                        beautifulAlert('Error!', 'Error sending form. Please try later.');
                    }
                }
                afterSendForm();
            });
            }
        })
	});

    const inputBlocks = document.querySelectorAll('.input__field');
    inputBlocks.forEach((input) => {
        input && input.addEventListener('focus', () => {
            input.closest('.input').classList.remove('input--error');
        });
    });
}
