$(() => {
    svg4everybody();
});

const showAllMobile = () => {
    const showMoreButton = document.querySelector('.js-quiz-load-more');
    if (document.body.clientWidth < 768 && showMoreButton) {
        showMoreButton.addEventListener('click', () => {
            const hiddenOffers = document.querySelectorAll('.quiz-list__item.mobile-hide');
            hiddenOffers.forEach(offer => {
                offer.classList.remove('mobile-hide');
            })
            showMoreButton.style.display = "none";
        })
    }
}

showAllMobile();
