const tabsNav = document.querySelectorAll('.tabs-nav');
if (tabsNav[0]) {
    tabsNav.forEach((tabNav, i) => {
        const buttonsBlock = tabNav.querySelectorAll('.tabs-nav__button-wrap');
        const buttons = tabNav.querySelectorAll('.tabs-nav__button');
        const contentContainers = tabNav.querySelectorAll('.tabs-nav__content');
        const removeActiveClass = () => {
            buttons.forEach((button, i) => {
                buttonsBlock[i].classList.remove('tabs-nav--active');
                contentContainers[i].classList.remove('tabs-nav--active');
            })
        }
        buttons.forEach((button, i) => {
            button.addEventListener('click', (evt) => {
                removeActiveClass();
                evt.preventDefault();
                if (!buttonsBlock[i].classList.contains('tabs-nav--active') && !contentContainers[i].classList.contains('tabs-nav--active')) {
                    buttonsBlock[i].classList.add('tabs-nav--active');
                    contentContainers[i].classList.add('tabs-nav--active');
                }
            })
        })
    })
}